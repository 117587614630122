import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Card variant="horizontal-content" image="aktuality/romantika.jpg" imagePosition="50% 30%" title="Romantika uprostred Raja" mdxType="Card">
      <p>{`Vezmite svoju polovičku na jesenné rande a doprajte si romantický pobyt v Studničkách.`}</p>
      <ul>
        <li parentName="ul">{`3 noci uprostred krásnej prírody,`}</li>
        <li parentName="ul">{`welcome drink pri príchode,`}</li>
        <li parentName="ul">{`výdatné raňajky a večere formou bufetu,`}</li>
        <li parentName="ul">{`kôš plný dobrôt pre piknik na romantickej hojdačke,`}</li>
        <li parentName="ul">{`fľaša vína na izbe pri príchode,`}</li>
        <li parentName="ul">{`výlety do farebných roklín Slovenského raja.`}</li>
      </ul>
      <p><strong parentName="p">{`Cena romatického pobytu: 239 €`}</strong></p>
      <ul>
        <li parentName="ul">{`Ponuka platí pre pobyty s dĺžkou 3 noci v termíne 1.9.2020 - 30.11.2020.`}</li>
      </ul>
    </Card>
    <Card variant="horizontal-content" image="aktuality/zlava-30-hojdacka.jpg" imagePosition="50% 80%" title="3 noci = 30% zľava" mdxType="Card">
      <p>{`Objavte krásy jesene v Slovenskom raji.`}<br />{`
Ak si u nás v mesiacoch október - november rezervujete pobyt na 3 noci získate 30% zľavu.`}</p>
      <ul>
        <li parentName="ul">{`3 noci uprostred krásnej prírody so zľavou 30%`}</li>
        <li parentName="ul">{`welcome drink pri príchode,`}</li>
        <li parentName="ul">{`výdatné raňajky a večere formou bufetu,`}</li>
        <li parentName="ul">{`výlety do farebných roklín Slovenského raja,`}</li>
        <li parentName="ul">{`možnosť požičania e-biku za príplatok.`}</li>
      </ul>
      <p><strong parentName="p">{`Cena pobytu: 189 €`}</strong></p>
      <ul>
        <li parentName="ul">{`Ponuka platí pre pobyty s dĺžkou 3 noci v termíne 1.10.2020 - 30.11.2020.`}</li>
      </ul>
    </Card>
    <Card variant="horizontal-content" image="aktuality/bicykle.jpg" imagePosition="50% 70%" title="Raj na biku" mdxType="Card">
      <p>{`Spoznajte Slovenský raj na bicykli. Už túto jeseň si môžete priamo u nás požičať e-bike.`}</p>
      <p>{`Požičanie bicykla podlieha dostupnosti.`}<br />{`
V prípade záujmu nás prosím kontaktujte min. 24 hod. vopred.`}</p>
      <p><strong parentName="p">{`Cena požičania e-biku: 25 € / deň`}</strong></p>
    </Card>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      